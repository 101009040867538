import './App.css';

function App() {
  return (
    <div className="App">
      MShembelev.ru
    </div>
  );
}

export default App;
